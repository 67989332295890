import React from 'react';
import { Text, Box, VStack, SimpleGrid } from '@chakra-ui/react';

import { IRouteParams } from '../../App';
import QRCode from 'react-qr-code';
import { Place } from '../../common/types';
import { getPlaces } from '../../common/api';

export default function Places(props: IRouteParams) {
    const [places, setPlaces] = React.useState({} as Record<string, Place>);

    React.useEffect(() => {
        if (props.auth === undefined) {
            props.startAuth();
        }
        getPlaces().then((places) => {
            setPlaces(places);
        });
    }, [props]);

    return (
        <Box width="100%" overflowWrap="break-word" padding="15" justifyContent={'center'}>
            <SimpleGrid columns={[1, null, 5]} spacing={25} justifyContent={'space-around'}>
                {Object.keys(places).map((placeKey, index) => (
                    <VStack spacing={3} onClick={() => props.route(`/order/${placeKey}`)} borderWidth="1px" key={index}>
                        <Text fontSize={25}>Table #{placeKey}</Text>
                        <QRCode size={300} value={`https://padong.co/order/${placeKey}`} />
                        <Text fontStyle={'italic'}>{places[placeKey]?.slug}</Text>
                    </VStack>
                ))}
            </SimpleGrid>
        </Box>
    );
}
