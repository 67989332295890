import { Box, Table, Thead, Tr, Th, Tbody, Td, Text, Button, HStack } from '@chakra-ui/react';
import { Place } from '../../common/types';

export default function PlacesTable(props: {
    places: Record<string, Place>;
    handleAddPlace: () => void;
    placeStats: Record<string, { activeCount: number; completeCount: number }>;
}) {
    const { places, placeStats } = props;
    return (
        <Box>
            <Text margin={5} textAlign="left">
                Places - {Object.keys(places).length}
            </Text>
            <HStack justifyContent={'flex-start'} mb={5}>
                <Button onClick={props.handleAddPlace}>+</Button>
            </HStack>
            <Table size="sm">
                <Thead>
                    <Tr>
                        <Th>Place Id</Th>
                        <Th>Slug</Th>
                        <Th isNumeric>Active Orders</Th>
                        <Th isNumeric>Completed Orders</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {Object.keys(places).map((placeKey, index) => (
                        <Tr key={index}>
                            <Td>{placeKey}</Td>
                            <Td>{places[placeKey]?.slug}</Td>
                            <Td isNumeric>{placeStats[placeKey].activeCount}</Td>
                            <Td isNumeric>{placeStats[placeKey].completeCount}</Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </Box>
    );
}
