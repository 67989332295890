import { RESTAURANT_API_URL } from '../constants';
import { OrderStatus, Order, AdminOptions, Place, AllData, HydratedOrder, OrderDTO, AllDataHydrated, ItemStatus, HydratedItem, Item, ItemTypes } from './types';

export async function updateOrderStatus(orderId: string, orderStatus: OrderStatus, auth: string): Promise<Order> {
    const response = await fetch(`${RESTAURANT_API_URL}/order/status`, {
        method: 'POST',
        headers: {
            padong_auth: auth,
        },
        body: JSON.stringify({
            orderId,
            newStatus: orderStatus,
        }),
    });
    return response.json();
}

export async function updateItemStatus(itemId: string, itemStatus: ItemStatus, auth: string): Promise<Item> {
    const response = await fetch(`${RESTAURANT_API_URL}/item/status`, {
        method: 'POST',
        headers: {
            padong_auth: auth,
        },
        body: JSON.stringify({
            itemId,
            newStatus: itemStatus,
        }),
    });
    return response.json();
}

export async function updateOrderPayment(orderId: string, payment: Order['payment'], auth: string): Promise<Order> {
    const response = await fetch(`${RESTAURANT_API_URL}/order/payment`, {
        method: 'POST',
        headers: {
            padong_auth: auth,
        },
        body: JSON.stringify({
            orderId,
            payment: payment,
        }),
    });
    return response.json();
}

export async function confirmOrder(orderId: string, payment: Order['payment'], auth: string): Promise<Order> {
    const response = await fetch(`${RESTAURANT_API_URL}/order/confirm`, {
        method: 'POST',
        headers: {
            padong_auth: auth,
        },
        body: JSON.stringify({
            orderId,
            payment: payment,
        }),
    });

    return response.json();
}

export async function getOrdersByPlaceId(placeId: string): Promise<Array<HydratedOrder>> {
    const response = await fetch(`${RESTAURANT_API_URL}/hydrated/activeOrderByPlaceId/${placeId}`);
    const json = await response.json();
    return json;
}

export async function getAdminOptions(): Promise<AdminOptions> {
    const response = await fetch(`${RESTAURANT_API_URL}/getAdminOptions`);
    const json = await response.json();
    return json;
}

export async function addOrder(order: OrderDTO, placeId: string): Promise<Order> {
    const response = await fetch(`${RESTAURANT_API_URL}/addOrder`, {
        method: 'POST',
        body: JSON.stringify({
            ...order,
            placeId,
        }),
    });
    return response.json();
}

export async function toggleItem(item: ItemTypes, auth: string): Promise<AdminOptions> {
    const response = await fetch(`${RESTAURANT_API_URL}/toggleItem/${item}`, {
        headers: {
            padong_auth: auth,
        },
    });
    return response.json();
}

export async function toggleOrderPing(orderNumber: number | string): Promise<Order> {
    const response = await fetch(`${RESTAURANT_API_URL}/toggleOrderPing/${orderNumber}`, {
        headers: {},
    });
    return response.json();
}

export async function addPlace(placeSlug: string, auth: string): Promise<Place> {
    const response = await fetch(`${RESTAURANT_API_URL}/addPlace`, {
        method: 'POST',
        headers: {
            padong_auth: auth,
        },
        body: JSON.stringify({
            slug: placeSlug,
        }),
    });
    return response.json();
}

export async function getPlaces(): Promise<Record<string, Place>> {
    const response = await fetch(`${RESTAURANT_API_URL}/getPlaces`);
    const json = await response.json();
    return json;
}

export async function getAll(auth: string): Promise<AllData> {
    const response = await fetch(`${RESTAURANT_API_URL}/getAll`, {
        headers: {
            padong_auth: auth,
        },
    });
    const json = await response.json();
    return json;
}

export async function getAllHydrated(auth: string): Promise<AllDataHydrated> {
    const response = await fetch(`${RESTAURANT_API_URL}/hydrated/all`, {
        headers: {
            padong_auth: auth,
        },
    });
    const json = await response.json();
    return json;
}

export async function getOrders(): Promise<Array<Order>> {
    const response = await fetch(`${RESTAURANT_API_URL}/getOrders`);
    const json = await response.json();
    return json;
}

export async function getOrdersHydrated(): Promise<Array<HydratedOrder>> {
    const response = await fetch(`${RESTAURANT_API_URL}/hydrated/orders`);
    const json = await response.json();
    return json;
}

export async function getItemsHydrated(): Promise<Array<HydratedItem>> {
    const response = await fetch(`${RESTAURANT_API_URL}/hydrated/items`);
    const json = await response.json();
    return json;
}
