import React from "react";

import { DateTime } from "luxon";

export function useLongPress(callback = () => {}, ms = 300) {
  const [startLongPress, setStartLongPress] = React.useState(false);

  React.useEffect(() => {
    let timerId: NodeJS.Timeout;
    if (startLongPress) {
      timerId = setTimeout(callback, ms);
    } else {
      //@ts-ignore
      clearTimeout(timerId);
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [callback, ms, startLongPress]);

  return {
    onMouseDown: () => setStartLongPress(true),
    onMouseUp: () => setStartLongPress(false),
    onMouseLeave: () => setStartLongPress(false),
    onTouchStart: () => setStartLongPress(true),
    onTouchEnd: () => setStartLongPress(false),
  };
}

export const timeBetween = (dateStringStart: string, dateStringEnd: string) => {
  const start = DateTime.fromISO(dateStringStart);
  const end = DateTime.fromISO(dateStringEnd);
  const diff = end.diff(start, "seconds");

  if (diff.seconds < 0) {
    return `impossible`;
  }
  if (diff.seconds < 60) {
    return `${Math.floor(diff.seconds)} seconds`;
  }

  if (diff.seconds > 60) {
    return `${Math.floor(diff.seconds / 60)} minutes`;
  }
};
