import { HydratedItem, HydratedOrder, Item, ItemStatus, ItemType, ItemTypes, Order, OrderStatus } from './types';

import { DateTime, Interval } from 'luxon';

export function getOrderStatus(order: HydratedOrder): OrderStatus {
    if (order.status === OrderStatus.PENDING) {
        return OrderStatus.PENDING;
    }

    if (order.status === OrderStatus.CANCELLED) {
        return OrderStatus.CANCELLED;
    }

    for (const item of order.items) {
        if (item.status === ItemStatus.CONFIRMED) {
            return OrderStatus.CONFIRMED;
        }
    }

    if (order.items.every((item) => item.status === ItemStatus.CANCELLED)) {
        return OrderStatus.CANCELLED;
    }

    return OrderStatus.COMPLETE;
}

export function hydrateOrder(order: Order, orders: Order[], items: Item[], itemDict: Record<ItemTypes, ItemType>): HydratedOrder {
    let finalOrder: HydratedOrder = {
        ...order,
        lastItemServeTime: '',
        items: getHydratedItemsByOrderId(order.id, items, orders, itemDict),
    };

    finalOrder.lastItemServeTime = getLastItemServeTime(finalOrder);

    return finalOrder;
}

// function hydrateItemsById(ids: Array<string>, items: Item[]): Array<HydratedItem> {
//     return ids.map((id) => items.find((item) => item.id === id));
// }

export function getHydratedItemsByOrderId(orderId: string, items: Item[], orders: Order[], itemDict: Record<ItemTypes, ItemType>): Array<HydratedItem> {
    const thisOrder = orders.find((o) => o.id === orderId);

    if (thisOrder === undefined) {
        throw new Error(`ORDER NOT FOUND: ${orderId}`);
    }
    return items
        .filter((item) => item.orderId === orderId)
        .reduce((acc, item) => acc.concat([{ ...item, typeDetails: itemDict[item.type], order: thisOrder }]), [] as Array<HydratedItem>);
}

export function hydrateItem(item: Item, orders: Array<Order>, itemDict: Record<ItemTypes, ItemType>): HydratedItem {
    const thisOrder = orders.find((o) => o.id === item.orderId);

    if (thisOrder === undefined) {
        throw new Error(`ORDER NOT FOUND: ${item.orderId}`);
    }
    return {
        ...item,
        order: thisOrder,
        typeDetails: itemDict[item.type],
    };
}

export function hydratedOrderPrice(order: HydratedOrder) {
    return order.items.reduce((acc, item) => acc + (item.status !== ItemStatus.CANCELLED && item.order.payment !== 'comp' ? item.typeDetails.priceGTQ : 0), 0);
}

export function hydratedOrderPriceTotal(order: HydratedOrder) {
    return order.items.reduce((acc, item) => acc + (item.order.payment !== 'comp' ? item.typeDetails.priceGTQ : 0), 0);
}

export function isReadyToServe(item: HydratedItem, orders: Array<HydratedOrder>): boolean {
    const thisOrder = orders.find((o) => o.id === item.orderId);
    if (!thisOrder) {
        throw new Error(`Order ${item.id} not found.`);
    }

    return thisOrder.items.filter((i) => i.status === ItemStatus.CONFIRMED).every((i) => i.typeDetails.serveOrder >= item.typeDetails.serveOrder);
}

export function lastItemLowerServeOrder(serveOrder: number, order: HydratedOrder): string {
    const lastItemChangeTime = order.items.reduce((acc, item) => {
        if (item.status !== ItemStatus.SERVED) {
            return acc;
        }
        if (item.typeDetails.serveOrder >= serveOrder) {
            return acc;
        }
        const itemModTimeMs = DateTime.fromISO(item.modTs).toMillis();
        return itemModTimeMs > acc ? itemModTimeMs : acc;
    }, DateTime.fromISO(order.ts).toMillis());

    return DateTime.fromMillis(lastItemChangeTime).toISO();
}

export function getLastItemServeTime(order: HydratedOrder): string {
    const lastItemChangeTime = order.items.reduce((acc, item) => {
        if (item.status !== ItemStatus.SERVED) {
            return acc;
        }
        const itemModTimeMs = DateTime.fromISO(item.modTs).toMillis();
        return itemModTimeMs > acc ? itemModTimeMs : acc;
    }, DateTime.fromISO(order.ts).toMillis());

    return DateTime.fromMillis(lastItemChangeTime).toISO();
}

export function getOrderById(orderId: string, orders: HydratedOrder[]): HydratedOrder {
    const thisOrder = orders.find((o) => o.id === orderId);

    if (thisOrder === undefined) {
        throw new Error('Order not found');
    }

    return thisOrder;
}

export function getItemBucketsByTime(items: HydratedItem[], tsStart: string, tsEnd: string, xUnitSeconds: number): Array<{ x: number; y: number }> {
    const startDT = DateTime.fromISO(tsStart);

    const thisInterval = Interval.fromISO(`${tsStart}/${tsEnd}`);
    const theseItems = items.filter((i) => thisInterval.contains(DateTime.fromISO(i.ts)));

    const secondsInInterval = thisInterval.count('seconds');

    const bucketCount = Math.round(secondsInInterval / xUnitSeconds);

    let data: Array<{ x: number; y: number }> = [];

    //x-axis
    for (let x = 0; x < bucketCount; x++) {
        const thisStart = startDT.plus({ seconds: xUnitSeconds * x });
        const thisEnd = startDT.plus({ seconds: xUnitSeconds * x + xUnitSeconds });
        const thisInterval = Interval.fromDateTimes(thisStart, thisEnd);
        const filteredItems = theseItems.filter((i) => thisInterval.contains(DateTime.fromISO(i.ts)));
        data.push({ x, y: filteredItems.length });
    }

    return data;
}

export function getItemServedBucketsByTime(items: HydratedItem[], tsStart: string, tsEnd: string, xUnitSeconds: number): Array<{ x: number; y: number }> {
    const startDT = DateTime.fromISO(tsStart);

    const thisInterval = Interval.fromISO(`${tsStart}/${tsEnd}`);
    const theseItems = items.filter((i) => thisInterval.contains(DateTime.fromISO(i.ts)));

    const secondsInInterval = thisInterval.count('seconds');

    const bucketCount = Math.round(secondsInInterval / xUnitSeconds);

    let data: Array<{ x: number; y: number }> = [{ x: 0, y: 0 }];

    //x-axis
    for (let x = 0; x < bucketCount; x++) {
        const thisStart = startDT.plus({ seconds: xUnitSeconds * x });
        const thisEnd = startDT.plus({ seconds: xUnitSeconds * x + xUnitSeconds });
        const thisInterval = Interval.fromDateTimes(thisStart, thisEnd);
        const filteredItems = theseItems.filter((i) => (i.completeTs !== undefined ? thisInterval.contains(DateTime.fromISO(i.completeTs)) : false));
        data.push({ x, y: filteredItems.length });
    }

    return data;
}

export function getItemAvgServeTimeBucketsByTime(items: HydratedItem[], tsStart: string, tsEnd: string, xUnitSeconds: number): Array<{ x: number; y: number }> {
    const startDT = DateTime.fromISO(tsStart);

    const thisInterval = Interval.fromISO(`${tsStart}/${tsEnd}`);
    const theseItems = items.filter((i) => thisInterval.contains(DateTime.fromISO(i.ts)));

    const secondsInInterval = thisInterval.count('seconds');

    const bucketCount = Math.round(secondsInInterval / xUnitSeconds);

    let data: Array<{ x: number; y: number }> = [{ x: 0, y: 0 }];

    //x-axis
    for (let x = 0; x < bucketCount; x++) {
        const thisStart = startDT.plus({ seconds: xUnitSeconds * x });
        const thisEnd = startDT.plus({ seconds: xUnitSeconds * x + xUnitSeconds });
        const thisInterval = Interval.fromDateTimes(thisStart, thisEnd);
        const filteredItems = theseItems.filter((i) => thisInterval.contains(DateTime.fromISO(i.completeTs || '')));
        data.push({ x, y: getTotalItemServeTimes(filteredItems) / (filteredItems.length || 1) });
    }

    return data;
}

export function getOrderAvgConfirmTimeBucketsByTime(
    orders: HydratedOrder[],
    tsStart: string,
    tsEnd: string,
    xUnitSeconds: number
): Array<{ x: number; y: number }> {
    const startDT = DateTime.fromISO(tsStart);

    const thisInterval = Interval.fromISO(`${tsStart}/${tsEnd}`);
    const theseOrders = orders.filter((o) => thisInterval.contains(DateTime.fromISO(o.ts)));

    const secondsInInterval = thisInterval.count('seconds');

    const bucketCount = Math.round(secondsInInterval / xUnitSeconds);

    let data: Array<{ x: number; y: number }> = [{ x: 0, y: 0 }];

    //x-axis
    for (let x = 0; x < bucketCount; x++) {
        const thisStart = startDT.plus({ seconds: xUnitSeconds * x });
        const thisEnd = startDT.plus({ seconds: xUnitSeconds * x + xUnitSeconds });
        const thisInterval = Interval.fromDateTimes(thisStart, thisEnd);
        const filteredOrders = theseOrders.filter((o) => thisInterval.contains(DateTime.fromISO(o.modTs || '')));
        data.push({ x, y: getTotalOrderConfirmTimeSeconds(filteredOrders) / (filteredOrders.length || 1) });
    }

    return data;
}

export function getTotalItemServeTimes(items: HydratedItem[]): number {
    return items.reduce((acc, item) => {
        const itemServeTime = getItemServeTimeSeconds(item);
        if (itemServeTime !== undefined) {
            return acc + itemServeTime;
        } else {
            return acc;
        }
    }, 0);
}

export function getItemServeTimeSeconds(item: HydratedItem): number | undefined {
    if (item.completeTs === undefined) {
        return undefined;
    }
    return Interval.fromISO(`${item.order.modTs}/${item.completeTs}`).length('seconds');
}

export function getTotalOrderConfirmTimeSeconds(orders: HydratedOrder[]): number {
    return orders.reduce((acc, order) => {
        const orderServeTime = getOrderConfirmTime(order);
        if (orderServeTime !== undefined) {
            return acc + orderServeTime;
        } else {
            return acc;
        }
    }, 0);
}

export function getOrderConfirmTime(order: HydratedOrder): number | undefined {
    if (order.modTs === undefined || order.ts === order.modTs) {
        return undefined;
    }
    return Interval.fromISO(`${order.ts}/${order.modTs}`).length('seconds');
}

export {};
