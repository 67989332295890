import React from 'react';
import { Divider, Text, Box, VStack, Button, HStack, Select, Input } from '@chakra-ui/react';

import { IRouteParams } from '../../App';
import { ItemStatus, OrderStatus } from '../../common/types';
import { updateItemStatus } from '../../common/api';
import ItemStatusElement from '../shared/ItemStatusElement';
import { getOrderById, isReadyToServe, lastItemLowerServeOrder } from '../../common/selectors';
import { timeAgoSeconds } from '../../common/orderUtils';

export default function Serve(props: IRouteParams) {
    const [statusFilter, setStatusFilter] = React.useState(OrderStatus.CONFIRMED as string);
    const [orderFilter, setOrderFilter] = React.useState('all' as string);
    const [nameFilter, setNameFilter] = React.useState('' as string);

    const { items, orders } = props.allData;

    const onUpdateItemStatus = (itemId: string, itemStatus: ItemStatus) => {
        updateItemStatus(itemId, itemStatus, props.auth || '').then(() => {
            props.refresh();
        });
    };

    let filteredItems = items.filter((item) => {
        if (statusFilter === 'all') {
            return true;
        }
        if (!isReadyToServe(item, orders)) {
            return false;
        }

        return item.status === statusFilter;
    });

    filteredItems = filteredItems.filter((item) => {
        if (nameFilter === '') {
            return true;
        }

        return item.order.name.toLowerCase().includes(nameFilter.toLowerCase());
    });

    filteredItems = filteredItems.filter((item) => {
        if (orderFilter === 'all') {
            return true;
        }
        return item.order.orderNumber.toString() === orderFilter;
    });

    filteredItems = filteredItems.sort((a, b) => {
        return timeAgoSeconds(lastItemLowerServeOrder(a.typeDetails.serveOrder, getOrderById(a.orderId, orders))) <
            timeAgoSeconds(lastItemLowerServeOrder(b.typeDetails.serveOrder, getOrderById(b.orderId, orders)))
            ? 1
            : -1;
    });

    return (
        <Box borderWidth="1px" borderRadius="lg" width="90%" overflowWrap="break-word" padding="5" maxWidth={600}>
            <Button onClick={props.refresh}>Refresh</Button>

            <VStack alignItems="flex-start" justifyContent={'flex-start'}>
                <Text>Items</Text>
                <HStack justifyItems={'space-around'} width="full">
                    <Select size="sm" onChange={(e) => setStatusFilter(e.target.value)} value={statusFilter}>
                        <option value={ItemStatus.CONFIRMED}>Ready To Serve</option>
                        <option value={ItemStatus.SERVED}>Served</option>
                        <option value={'all'}>All Statuses</option>
                    </Select>
                </HStack>
                <HStack justifyItems={'space-around'} width="full">
                    <Select size="sm" onChange={(e) => setOrderFilter(e.target.value)} value={orderFilter}>
                        <option value={'all'}>All Orders</option>
                        {orders.map((o, index) => (
                            <option key={index} value={o.orderNumber}>
                                {o.orderNumber} - {o.name}
                            </option>
                        ))}
                    </Select>
                </HStack>
                <HStack justifyItems={'space-around'} width="full">
                    <Input size="sm" onChange={(e) => setNameFilter(e.target.value)} value={nameFilter} placeholder="filter by name" />
                </HStack>

                {/* <RadioGroup onChange={setStatusFilter} value={statusFilter}>
                    <SimpleGrid columns={[2, null, 5]} spacing={3}>
                        <Radio value={ItemStatus.PENDING}>Pending</Radio>
                        <Radio value={ItemStatus.CONFIRMED}>Confirmed</Radio>
                        <Radio value={ItemStatus.SERVED}>Served</Radio>
                        <Radio value={ItemStatus.CANCELLED}>Cancelled</Radio>
                        <Radio value="all">All</Radio>
                    </SimpleGrid>
                </RadioGroup> */}
                <Divider />
                <Text fontSize={14}>{filteredItems.length} item(s) </Text>
                <Divider />
                {filteredItems.map((item, index) => (
                    <ItemStatusElement
                        key={index}
                        item={item}
                        updateItemStatus={onUpdateItemStatus}
                        refreshParent={props.refresh}
                        withHeader={true}
                        order={getOrderById(item.orderId, orders)}
                    />
                ))}
            </VStack>
        </Box>
    );
}
