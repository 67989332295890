import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import {
    Box,
    Badge,
    VStack,
    Button,
    Divider,
    Text,
    SimpleGrid,
    List,
    ListItem,
    Alert,
    AlertIcon,
    AlertTitle,
    CloseButton,
    ButtonGroup,
} from '@chakra-ui/react';
import { toggleOrderPing } from '../../common/api';
import { timeAgoSeconds, timeAgoFormat } from '../../common/orderUtils';
import { getOrderStatus, hydratedOrderPrice } from '../../common/selectors';
import { timeBetween } from '../../common/serveUtils';
import { OrderStatus, HydratedOrder, ItemStatus, Order, statusColorDict } from '../../common/types';

export default function OrderStatusItem(props: {
    order: HydratedOrder;
    refreshParent?: () => void;
    onConfirmOrder: (orderId: string, payment: Order['payment']) => void;
    updateOrderStatus: (orderId: string, orderStatus: OrderStatus) => void;
}) {
    const orderTimeAgo = timeAgoSeconds(props.order.ts);
    const orderModTimeAgo = timeAgoSeconds(props.order.lastItemServeTime);

    const orderPrice = hydratedOrderPrice(props.order);
    const orderStatus = getOrderStatus(props.order);

    const handleOrderPing = async (orderNumber: string | number) => {
        const confirm = window.confirm('Table ping resolved?');
        if (!confirm) {
            return;
        }
        await toggleOrderPing(props.order.orderNumber);
        props.refreshParent?.();
    };

    const handleStatus = (e: any) => {
        //little bounce
        setTimeout(() => {
            if (e.target.name === 'comp') {
                //confirm order
                const confirm = window.confirm(`Order #${props.order.orderNumber} is comped?`);
                if (!confirm) {
                    return;
                }
                props.onConfirmOrder(props.order.id, 'comp');
            } else if (e.target.name === 'cancel') {
                const confirm = window.confirm(`Cancel order #${props.order.orderNumber}?`);
                if (!confirm) {
                    return;
                }
                props.updateOrderStatus(props.order.id, OrderStatus.CANCELLED);
            } else if (e.target.name === 'cash') {
                const confirm = window.confirm(`Order #${props.order.orderNumber} paid cash?`);
                if (!confirm) {
                    return;
                }
                props.onConfirmOrder(props.order.id, 'cash');
            } else if (e.target.name === 'card') {
                const confirm = window.confirm(`Order #${props.order.orderNumber} paid by card?`);
                if (!confirm) {
                    return;
                }
                props.onConfirmOrder(props.order.id, 'card');
            }
        }, 75);
    };

    let borderColor = 'green.300';
    if (orderStatus !== OrderStatus.COMPLETE && orderStatus !== OrderStatus.CANCELLED) {
        const orderModTimeAgoMin = orderModTimeAgo / 60;
        if (orderModTimeAgoMin > 5 && orderModTimeAgoMin < 15) {
            borderColor = 'yellow.500';
        } else if (orderModTimeAgoMin > 15 && orderModTimeAgoMin < 30) {
            borderColor = 'orange.500';
        } else if (orderModTimeAgoMin > 30) {
            borderColor = 'red.500';
        } else {
            borderColor = '';
        }
    }

    return (
        <Box borderWidth="3px" borderRadius="lg" margin={2} padding={3} width="100%" borderColor={borderColor}>
            {props.order.ping && (
                <Alert status="warning" variant="solid" fontSize={13} width="full">
                    <AlertIcon />
                    <AlertTitle mr={2}>{props.order.name} has pinged</AlertTitle>
                    <CloseButton position="absolute" right="8px" top="8px" onClick={() => handleOrderPing(props.order.orderNumber)} />
                </Alert>
            )}
            <Badge width="full" colorScheme={statusColorDict[orderStatus]} size="md">
                {orderStatus}
            </Badge>
            <SimpleGrid columns={[1, 1, 2, 2, 2]} spacing={1} padding={1} margin={1}>
                <Badge fontSize={15} padding={1} bg="gray.600">
                    Order #{props.order.orderNumber} - {props.order.name}
                </Badge>

                <Badge fontSize={17} padding={1}>
                    Table {props.order.placeId}
                </Badge>
            </SimpleGrid>
            <Divider />
            <VStack padding={2}>
                <List fontSize={13} textAlign="left">
                    {props.order.items.map((item, index) => (
                        <ListItem key={index}>
                            {item.status !== ItemStatus.CANCELLED ? (
                                `${item.typeDetails.displayName} ${item.status === ItemStatus.SERVED ? '(Served)' : ''} - Q${item.typeDetails.priceGTQ}`
                            ) : (
                                <s>{item.typeDetails.displayName}</s>
                            )}
                        </ListItem>
                    ))}
                </List>
                <Text>
                    Total: Q {orderPrice} {props.order.payment !== undefined ? '(Paid)' : ''}
                </Text>
            </VStack>

            <Divider />
            {orderStatus === OrderStatus.PENDING && (
                <SimpleGrid columns={[1, 1, 2, 2, 2]} spacing={1} padding={2}>
                    <Button
                        leftIcon={<CloseIcon />}
                        mr="-px"
                        colorScheme="red"
                        size="sm"
                        name="cancel"
                        onClick={handleStatus}
                        disabled={props.order.items.some((i) => i.status === ItemStatus.CONFIRMED || i.status === ItemStatus.SERVED)}
                    >
                        Cancel
                    </Button>

                    <ButtonGroup isAttached={true} variant="outline">
                        <Button rightIcon={<CheckIcon />} mr="-px" padding="1" size="sm" width="full" colorScheme="orange" name="comp" onClick={handleStatus}>
                            Comp
                        </Button>
                        <Button rightIcon={<CheckIcon />} mr="-px" size="sm" width="full" colorScheme="blue" name="card" onClick={handleStatus}>
                            Card
                        </Button>
                        <Button rightIcon={<CheckIcon />} mr="-px" size="sm" width="full" colorScheme="green" name="cash" onClick={handleStatus}>
                            Cash
                        </Button>
                    </ButtonGroup>

                    {/* <Button
                    rightIcon={<CheckCircleIcon />}
                    mr="-px"
                    colorScheme="green"
                    size="sm"
                    disabled={props.order.payment !== undefined || props.order.status === OrderStatus.CANCELLED}
                    name="paid"
                    onClick={handleStatus}
                >
                    Paid
                </Button> */}
                </SimpleGrid>
            )}

            <Divider />
            {orderStatus !== OrderStatus.COMPLETE && (
                <VStack justifyContent="flex-start" alignItems={'flex-start'} padding={1}>
                    <Text fontSize={14} fontStyle={'italic'}>
                        Placed {timeAgoFormat(orderTimeAgo)} ago
                    </Text>
                    <Divider />
                    <Text fontSize={14} fontStyle={'italic'}>
                        waiting for {timeAgoFormat(orderModTimeAgo)}
                    </Text>
                </VStack>
            )}
            {orderStatus === OrderStatus.COMPLETE && (
                <VStack justifyContent="flex-start" alignItems={'flex-start'}>
                    <Text fontSize={14}>
                        <i>Completed in {timeBetween(props.order.ts, props.order.lastItemServeTime)}</i>
                    </Text>
                </VStack>
            )}
        </Box>
    );
}
