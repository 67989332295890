import { Text, Box, Table, Tbody, Td, Th, Thead, Tr, Button, ButtonGroup, Switch, HStack, Tfoot, SimpleGrid } from '@chakra-ui/react';

import { IRouteParams } from '../../App';
import { ItemsDict, ItemStatus, ItemTypes, OrderStatus } from '../../common/types';
import PlacesTable from './PlacesTable';
import { addPlace, getAll, toggleItem } from '../../common/api';
import {
    getItemBucketsByTime,
    getOrderStatus,
    hydratedOrderPrice,
    getItemServedBucketsByTime,
    getItemAvgServeTimeBucketsByTime,
    getOrderAvgConfirmTimeBucketsByTime,
} from '../../common/selectors';
import { DateTime, Duration } from 'luxon';
import { VictoryAxis, VictoryChart, VictoryLegend, VictoryLine, VictoryTheme, VictoryTooltip } from 'victory';

export default function Admin(props: IRouteParams) {
    const { orders, places, adminOptions, items } = props.allData;

    const startDT = DateTime.fromISO('2022-01-10T21:00:00.000Z');
    const itemOrders = getItemBucketsByTime(
        items,
        startDT.toISO(),
        DateTime.fromISO('2022-01-10T21:48:05.244Z')
            .plus(Duration.fromObject({ hours: 5 }))
            .toISO(),
        60 * 15
    );

    const springRollOrders = getItemBucketsByTime(
        items.filter((i) => i.type === ItemTypes['spring-rolls-fried']),
        startDT.toISO(),
        DateTime.fromISO('2022-01-10T21:48:05.244Z')
            .plus(Duration.fromObject({ hours: 5 }))
            .toISO(),
        60 * 15
    );

    const phoOrders = getItemBucketsByTime(
        items.filter((i) => i.type === ItemTypes['korean-tacos-chicken'] || i.type === ItemTypes['korean-tacos-veggie']),
        startDT.toISO(),
        DateTime.fromISO('2022-01-10T21:48:05.244Z')
            .plus(Duration.fromObject({ hours: 5 }))
            .toISO(),
        60 * 15
    );

    const dessertOrders = getItemBucketsByTime(
        items.filter((i) => i.type === ItemTypes['choco-truffles']),
        startDT.toISO(),
        startDT.plus(Duration.fromObject({ hours: 5 })).toISO(),
        60 * 15
    );

    const itemServed = getItemServedBucketsByTime(items, startDT.toISO(), startDT.plus(Duration.fromObject({ hours: 5 })).toISO(), 60 * 15);

    const itemServeTimes = getItemAvgServeTimeBucketsByTime(items, startDT.toISO(), startDT.plus(Duration.fromObject({ hours: 5 })).toISO(), 60 * 15);

    const orderConfirmTimes = getOrderAvgConfirmTimeBucketsByTime(orders, startDT.toISO(), startDT.plus(Duration.fromObject({ hours: 5 })).toISO(), 60 * 15);

    const handleToggleItem = async (item: string) => {
        try {
            await toggleItem(item as ItemTypes, props.auth || '');
        } catch (error) {
            console.log(error);
        }

        props.refresh();
    };

    const placeStats = Object.keys(places).reduce((acc, placeKey) => {
        let thisPlaceValue: { activeCount: number; completeCount: number } = {
            activeCount: 0,
            completeCount: 0,
        };

        for (const order of orders) {
            if (order.placeId === placeKey) {
                if (getOrderStatus(order) === OrderStatus.COMPLETE) {
                    thisPlaceValue.completeCount++;
                } else {
                    thisPlaceValue.activeCount++;
                }
            }
        }

        return {
            ...acc,
            [placeKey]: thisPlaceValue,
        };
    }, {} as Record<string, { activeCount: number; completeCount: number }>);

    // const items = {};

    const handleAddPlace = async () => {
        const placeSlug = window.prompt('Simple place name?');
        if (placeSlug === null || placeSlug === '') {
            return;
        }
        try {
            await addPlace(placeSlug, props.auth || '');
        } catch (error) {
            console.log(error);
        }

        props.refresh();
    };

    const handleBackup = async () => {
        const allData = await getAll(props.auth || '');
        const json = JSON.stringify(allData);
        const blob = new Blob([json], { type: 'application/json' });
        const href = await URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = href;
        const fileName = 'padong-co-' + DateTime.now().toFormat('MM-dd-yy_hh-mm-ss');
        link.download = fileName + '.json';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <Box width="100%" overflowWrap="break-word" padding="5" justifyContent="flex-start">
            <HStack justifyContent="flex-end" margin={2}>
                <Button onClick={handleBackup} variant="outline">
                    Backup
                </Button>
                <Button onClick={props.refresh}>Refresh</Button>

                <ButtonGroup isAttached={true} size="md">
                    <Button onClick={() => props.route('/kitchen')} variant="outline">
                        Kitchen VIew
                    </Button>
                </ButtonGroup>
            </HStack>

            <Text margin={5} textAlign="left">
                Items
            </Text>
            <Table size="sm">
                <Thead>
                    <Tr>
                        <Th>Item Name</Th>
                        <Th>Soldout</Th>
                        <Th isNumeric>Pending</Th>
                        <Th isNumeric>Confirmed</Th>
                        <Th isNumeric>Served</Th>
                        <Th isNumeric>Total</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {(Object.keys(ItemsDict) as ItemTypes[]).map((itemKey, index) => {
                        const theseItems = items.filter((i) => i.type === itemKey);
                        return (
                            <Tr key={index}>
                                <Td>{ItemsDict[itemKey].displayName}</Td>
                                <Td>
                                    <Switch size="md" isChecked={adminOptions.disabledItems[itemKey as ItemTypes]} onChange={() => handleToggleItem(itemKey)} />
                                </Td>
                                <Td isNumeric>{theseItems.filter((i) => i.status === ItemStatus.PENDING).length}</Td>
                                <Td isNumeric>{theseItems.filter((i) => i.status === ItemStatus.CONFIRMED).length}</Td>
                                <Td isNumeric>{theseItems.filter((i) => i.status === ItemStatus.SERVED).length}</Td>
                                <Td isNumeric>{theseItems.filter((i) => i.status !== ItemStatus.CANCELLED).length}</Td>
                            </Tr>
                        );
                    })}
                </Tbody>
            </Table>
            <PlacesTable places={places} placeStats={placeStats} handleAddPlace={handleAddPlace} />

            <Text margin={5} textAlign="left">
                Orders - {orders.length}
            </Text>
            <Table size="sm">
                <Thead>
                    <Tr>
                        <Th>Order #</Th>
                        <Th>Place #</Th>
                        <Th>Name</Th>
                        <Th>Status</Th>

                        <Th>Payment</Th>
                        <Th isNumeric={true}>Total</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {orders.map((order, index) => (
                        <Tr key={index}>
                            <Td>{order.orderNumber}</Td>
                            <Td>{order.placeId}</Td>
                            <Td>{order.name}</Td>
                            <Td>{getOrderStatus(order)}</Td>

                            <Td>{order.payment || 'n/a'}</Td>
                            <Td isNumeric={true}>Q {hydratedOrderPrice(order)}</Td>
                        </Tr>
                    ))}
                </Tbody>
                <Tfoot fontStyle={'oblique'}>
                    <Tr>
                        <Td>Totals</Td>
                        <Td></Td>
                        <Td></Td>
                        <Td></Td>
                        <Td></Td>
                        <Td isNumeric={true}>Q {orders.reduce((acc, o) => acc + hydratedOrderPrice(o), 0)}</Td>
                    </Tr>
                </Tfoot>
            </Table>
            <SimpleGrid columns={[1, 1, 2, 2, 2, 3]} padding={10} width="full">
                <Box width="full">
                    <VictoryChart theme={VictoryTheme.material} domainPadding={{ y: [0, 5] }}>
                        <VictoryLegend
                            title="Item counts"
                            orientation="horizontal"
                            gutter={20}
                            height={30}
                            style={{ border: { stroke: 'white' }, title: { fontSize: 10 }, data: { fontSize: 10 }, labels: { fontSize: 10 } }}
                            data={[
                                { name: 'Ordered', symbol: { fill: 'tomato' } },
                                { name: 'Served', symbol: { fill: 'green' } },
                            ]}
                        />
                        <VictoryLine
                            style={{
                                data: { stroke: '#c43a31', strokeWidth: 1 },
                                parent: { border: '1px solid #ccc' },
                            }}
                            data={itemOrders}
                        />
                        <VictoryLine
                            style={{
                                data: { stroke: 'green', strokeWidth: 1 },
                                parent: { border: '1px solid #ccc' },
                            }}
                            data={itemServed}
                        />
                        <VictoryAxis
                            style={{ tickLabels: { fontSize: 7, padding: 1 } }}
                            tickValues={[2, 4, 6, 8, 10, 12, 14, 16, 18, 20]}
                            // scale={'time'}
                            // tickCount={5}
                            tickFormat={(t) => `${startDT.plus({ minutes: t * 15 }).toFormat('hh:mm')} `}
                        />
                        <VictoryAxis dependentAxis domain={[0, 40]} />
                    </VictoryChart>
                </Box>
                <Box width="full">
                    <VictoryChart theme={VictoryTheme.material} domainPadding={{}}>
                        <VictoryLegend
                            title="Avg serve time"
                            orientation="horizontal"
                            gutter={20}
                            style={{ border: { stroke: 'white' }, title: { fontSize: 10 }, data: { fontSize: 10 }, labels: { fontSize: 10 } }}
                            data={[]}
                        />
                        <VictoryLine
                            style={{
                                data: { stroke: 'pink' },
                                parent: { border: '1px solid #ccc' },
                            }}
                            data={itemServeTimes}
                        />
                        <VictoryAxis
                            style={{ tickLabels: { fontSize: 7, padding: 1 } }}
                            tickValues={[2, 4, 6, 8, 10, 12, 14, 16, 18, 20]}
                            // scale={'time'}
                            // tickCount={5}
                            tickFormat={(t) => `${startDT.plus({ minutes: t * 15 }).toFormat('hh:mm')} `}
                        />
                        <VictoryTooltip />
                        <VictoryAxis dependentAxis tickFormat={(t) => `${Math.round(t / 60)}m`} tickCount={7} domain={[0, 60 * 60 * 1.25]} />
                    </VictoryChart>
                </Box>
                <Box width="full">
                    <VictoryChart theme={VictoryTheme.material} domainPadding={{ y: [0, 5] }}>
                        <VictoryLegend
                            title="Item Orders"
                            orientation="horizontal"
                            gutter={20}
                            style={{ border: { stroke: 'white' }, title: { fontSize: 10 }, data: { fontSize: 10 }, labels: { fontSize: 10 } }}
                            data={[
                                { name: 'Spring Rolls', symbol: { fill: 'tomato' } },
                                { name: 'Pho', symbol: { fill: 'pink' } },
                                { name: 'Dessert', symbol: { fill: 'brown' } },
                            ]}
                        />
                        <VictoryLine
                            style={{
                                data: { stroke: 'tomato' },
                                parent: { border: '1px solid #ccc' },
                            }}
                            data={springRollOrders}
                        />
                        <VictoryLine
                            style={{
                                data: { stroke: 'pink' },
                                parent: { border: '1px solid #ccc' },
                            }}
                            data={phoOrders}
                        />
                        <VictoryLine
                            style={{
                                data: { stroke: 'brown' },
                                parent: { border: '1px solid #ccc' },
                            }}
                            data={dessertOrders}
                        />
                        <VictoryAxis
                            style={{ tickLabels: { fontSize: 7, padding: 1 } }}
                            tickValues={[2, 4, 6, 8, 10, 12, 14, 16, 18, 20]}
                            // scale={'time'}
                            // tickCount={5}

                            tickFormat={(t) => `${startDT.plus({ minutes: t * 15 }).toFormat('hh:mm')} `}
                        />
                        <VictoryTooltip />
                        <VictoryAxis dependentAxis tickFormat={(t) => `${t}`} domain={[0, 25]} />
                    </VictoryChart>
                </Box>
                <Box width="full">
                    <VictoryChart theme={VictoryTheme.material} domainPadding={{ y: [0, 5] }}>
                        <VictoryLegend
                            title="Avg order confirm time"
                            orientation="horizontal"
                            gutter={20}
                            x={0}
                            y={0}
                            style={{ border: { stroke: 'white' }, title: { fontSize: 10 }, data: { fontSize: 10 }, labels: { fontSize: 10 } }}
                            data={[]}
                        />
                        <VictoryLine
                            style={{
                                data: { stroke: 'purple' },
                                parent: { border: '1px solid #ccc' },
                            }}
                            data={orderConfirmTimes}
                        />
                        <VictoryAxis
                            style={{ tickLabels: { fontSize: 7, padding: 1 } }}
                            tickValues={[2, 4, 6, 8, 10, 12, 14, 16, 18, 20]}
                            // scale={'time'}
                            // tickCount={5}
                            tickFormat={(t) => `${startDT.plus({ minutes: t * 15 }).toFormat('hh:mm')} `}
                        />
                        <VictoryTooltip />
                        <VictoryAxis dependentAxis tickFormat={(t) => `${Math.round(t / 60)}m`} tickCount={7} domain={[0, 60 * 60 * 1.25]} />
                    </VictoryChart>
                </Box>
            </SimpleGrid>
        </Box>
    );
}
