import { HStack, Text, Box, VStack, Button, ButtonGroup, SimpleGrid, Image } from '@chakra-ui/react';

import { IRouteParams } from '../../App';
import { ItemsDict, ItemStatus, ItemTypes } from '../../common/types';
import { updateItemStatus } from '../../common/api';
import ItemStatusElement from '../shared/ItemStatusElement';
import { getOrderById, isReadyToServe, lastItemLowerServeOrder } from '../../common/selectors';
import { timeAgoSeconds } from '../../common/orderUtils';

export default function Kitchen(props: IRouteParams) {
    const { items, orders } = props.allData;

    const onUpdateItemStatus = (itemId: string, itemStatus: ItemStatus) => {
        updateItemStatus(itemId, itemStatus, props.auth || '').then(() => {
            props.refresh();
        });
    };

    const sortedItems = items.sort((a, b) => {
        return timeAgoSeconds(lastItemLowerServeOrder(a.typeDetails.serveOrder, getOrderById(a.orderId, orders))) <
            timeAgoSeconds(lastItemLowerServeOrder(b.typeDetails.serveOrder, getOrderById(b.orderId, orders)))
            ? 1
            : -1;
    });

    return (
        <Box width="100%" overflowWrap="break-word" padding="5">
            <HStack justifyContent="flex-end" margin={2}>
                <Button onClick={props.refresh}>Refresh</Button>
                <ButtonGroup isAttached={true} size="md" justifySelf="flex-start">
                    <Button onClick={() => props.route('/admin')} variant="outline">
                        Admin VIew
                    </Button>
                </ButtonGroup>
            </HStack>
            <SimpleGrid columns={[1, null, 5]} spacing={2}>
                {(Object.keys(ItemsDict) as ItemTypes[])
                    .sort((a, b) => (ItemsDict[a].serveOrder > ItemsDict[b].serveOrder ? 1 : -1))
                    .map((itemType, index) => {
                        const theseItems = sortedItems
                            .filter((item) => item.type === itemType)
                            .filter((item) => item.status === ItemStatus.CONFIRMED && isReadyToServe(item, orders));
                        return (
                            <VStack borderWidth="1px" borderRadius="lg" padding={2} key={index}>
                                <HStack padding={5}>
                                    <Image src={ItemsDict[itemType as ItemTypes].image} maxWidth="40px" />
                                    <Text fontWeight="bold">
                                        {ItemsDict[itemType as ItemTypes].displayName} - {theseItems.length}
                                    </Text>
                                </HStack>

                                {theseItems.map((item, index) => (
                                    <ItemStatusElement
                                        order={getOrderById(item.orderId, orders)}
                                        key={index}
                                        item={item}
                                        updateItemStatus={onUpdateItemStatus}
                                        refreshParent={props.refresh}
                                        withHeader={false}
                                    />
                                ))}
                            </VStack>
                        );
                    })}
            </SimpleGrid>
        </Box>
    );
}
