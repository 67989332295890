export const LOCAL_STORAGE_KEY = 'padong';

export const APP_STORE_PREFIX = 'padong';

export const STORE_URL = process.env.NODE_ENV === 'production' ? 'https://simple-store.bodhi-cloud.workers.dev' : 'http://127.0.0.1:8787';

export const RESTAURANT_API_URL = process.env.NODE_ENV === 'production' ? 'https://padong.co/api/restaurant' : 'http://127.0.0.1:8787';

export const CRYPTO_PAY_URL = process.env.NODE_ENV === 'production' ? 'https://coinbase-charge.bodhi-cloud.workers.dev' : 'http://127.0.0.1:8787';

export const PAYMENT_AMOUNT = process.env.NODE_ENV === 'production' ? '13.00' : '0.20';

export const LOCAL_AUTH_KEY = 'PADONG_AUTH';
