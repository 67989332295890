import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { Box, Badge, VStack, Button, Divider, Text, SimpleGrid, HStack, Alert, Image, AlertIcon, AlertTitle, CloseButton, ButtonGroup } from '@chakra-ui/react';
import { toggleOrderPing } from '../../common/api';
import { timeAgoSeconds, timeAgoFormat } from '../../common/orderUtils';
import { lastItemLowerServeOrder } from '../../common/selectors';
import { timeBetween } from '../../common/serveUtils';
import { HydratedItem, HydratedOrder, ItemStatus } from '../../common/types';

export default function ItemStatusElement(props: {
    item: HydratedItem;
    order: HydratedOrder;
    refreshParent?: () => void;
    withHeader: boolean;
    updateItemStatus: (itemId: string, itemStatus: ItemStatus) => void;
}) {
    const orderTimeAgo = timeAgoSeconds(props.item.ts);
    const lastOrderUpdateSeconds = timeAgoSeconds(lastItemLowerServeOrder(props.item.typeDetails.serveOrder, props.order));
    //const orderModTimeAgo = timeAgoSeconds(props.item.modTs);

    const handleOrderPing = async (orderNumber: string | number) => {
        const confirm = window.confirm('Table ping resolved?');
        if (!confirm) {
            return;
        }

        await toggleOrderPing(props.item.order.orderNumber);
        props.refreshParent?.();
    };

    const handleStatus = (e: any) => {
        if (e.target.name === 'confirm') {
            //confirm item
            const confirm = window.confirm(
                `move ${props.item.typeDetails.displayName} for order #${props.item.order.orderNumber} - ${props.item.order.name} to confirmed?`
            );
            if (!confirm) {
                return;
            }

            props.updateItemStatus(props.item.id, ItemStatus.CONFIRMED);
        } else if (e.target.name === 'cancel') {
            const confirm = window.confirm(
                `move ${props.item.typeDetails.displayName} for order #${props.item.order.orderNumber} - ${props.item.order.name} to cancelled?`
            );

            if (!confirm) {
                return;
            }
            const confirm2 = window.confirm(`ARE YOU SURE YOU WANT TO CANCEL THIS ITEM?`);

            if (!confirm2) {
                return;
            }

            props.updateItemStatus(props.item.id, ItemStatus.CANCELLED);
        } else if (e.target.name === 'served') {
            const confirm = window.confirm(
                `move ${props.item.typeDetails.displayName} for order #${props.item.order.orderNumber} - ${props.item.order.name} to served?`
            );
            if (!confirm) {
                return;
            }

            props.updateItemStatus(props.item.id, ItemStatus.SERVED);
        }
    };

    let borderColor = 'green.300';
    if (props.item.status !== ItemStatus.SERVED && props.item.status !== ItemStatus.CANCELLED) {
        const lastOrderUpdateMin = lastOrderUpdateSeconds / 60;
        if (lastOrderUpdateMin > 5 && lastOrderUpdateMin < 15) {
            borderColor = 'yellow.500';
        } else if (lastOrderUpdateMin > 15 && lastOrderUpdateMin < 30) {
            borderColor = 'orange.500';
        } else if (lastOrderUpdateMin > 30) {
            borderColor = 'red.500';
        } else {
            borderColor = '';
        }
    }
    if (props.item.status === ItemStatus.CANCELLED) {
        borderColor = 'purple.500';
    }

    return (
        <Box borderWidth="3px" borderRadius="lg" margin={2} padding={3} width="100%" borderColor={borderColor}>
            {props.item.order.ping && (
                <Alert status="warning" variant="solid" fontSize={13} width="full">
                    <AlertIcon />
                    <AlertTitle mr={2}>{props.item.order.name} has pinged</AlertTitle>
                    <CloseButton position="absolute" right="8px" top="8px" onClick={() => handleOrderPing(props.item.order.orderNumber)} />
                </Alert>
            )}
            {props.withHeader && (
                <VStack>
                    <Divider />
                    <HStack padding={1}>
                        <Image src={props.item.typeDetails.image} maxWidth="40px" />
                        <Text fontWeight="bold">{props.item.typeDetails.displayName}</Text>
                    </HStack>
                    <Divider />
                </VStack>
            )}
            <SimpleGrid columns={[1, 1, 1, 1, 1]} spacing={1} padding={2} margin={1}>
                <Badge fontSize={16} padding={1} bg={borderColor} overflow={'auto'} whiteSpace="normal">
                    Order #{props.item.order.orderNumber} - {props.item.order.name}
                </Badge>

                <HStack>
                    <Badge fontSize={17} padding={1} whiteSpace="normal" width="full">
                        Table {props.item.order.placeId}
                    </Badge>
                </HStack>
            </SimpleGrid>
            <Divider />
            <Text fontSize={17} padding={1} textColor={borderColor} whiteSpace="normal" width="full" fontWeight={600}>
                Waiting {timeAgoFormat(lastOrderUpdateSeconds)}
            </Text>
            <SimpleGrid columns={[1, 1, 1, 2, 2]} spacing={1} padding={2}>
                {props.item.status !== ItemStatus.SERVED && (
                    <VStack justifyContent="center" alignItems={'flex-start'}>
                        <Text fontSize={14} fontStyle={'italic'}>
                            placed {timeAgoFormat(orderTimeAgo)} ago
                        </Text>
                    </VStack>
                )}
                {props.item.status === ItemStatus.SERVED && props.item.completeTs !== undefined && (
                    <VStack justifyContent="flex-start" alignItems={'flex-start'}>
                        <Text fontSize={14}>
                            <i>Completed in {timeBetween(props.item.ts, props.item.completeTs)}</i>
                        </Text>
                    </VStack>
                )}
                <HStack width="full">
                    {props.item.status === ItemStatus.CONFIRMED && (
                        <ButtonGroup isAttached={true} whiteSpace="normal" width="full">
                            <Button colorScheme="red" height="auto" onClick={() => handleStatus({ target: { name: 'cancel' } })}>
                                x
                            </Button>
                            <Button
                                rightIcon={<CheckIcon />}
                                mr="-px"
                                whiteSpace="normal"
                                size="md"
                                width="full"
                                height="100%"
                                colorScheme="green"
                                padding={2}
                                name="served"
                                onClick={handleStatus}
                            >
                                {props.item.typeDetails.displayName}
                            </Button>
                        </ButtonGroup>
                    )}
                    {props.item.status === ItemStatus.SERVED && (
                        <Button
                            rightIcon={<CloseIcon />}
                            mr="-px"
                            size="md"
                            width="full"
                            height="100%"
                            colorScheme="red"
                            padding={2}
                            name="confirm"
                            onClick={handleStatus}
                        >
                            undo {props.item.typeDetails.displayName}
                        </Button>
                    )}
                </HStack>
            </SimpleGrid>

            <Divider />

            {/* <VStack alignItems={'flex-start'} mt={2} mb={2}>
                    
                </VStack> */}
        </Box>
    );
}
