import * as React from 'react';
import {
    ChakraProvider,
    Box,
    VStack,
    Grid,
    Image,
    theme,
    Button,
    Spinner,
    Center,
    HStack,
    ButtonGroup,
    Tag,
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
} from '@chakra-ui/react';

import { ColorModeSwitcher } from './ColorModeSwitcher';

import { LOCAL_AUTH_KEY, RESTAURANT_API_URL } from './constants';
import Order from './components/Order/Order';
import Serve from './components/Serve/Serve';
import Items from './components/Items/Items';
import Kitchen from './components/Kitchen/Kitchen';
import Admin from './components/Admin/Admin';
import { AllDataHydrated, initialOptions, OrderStatus } from './common/types';
import { getAllHydrated } from './common/api';
import { SmallCloseIcon } from '@chakra-ui/icons';
import Places from './components/Admin/Places';

declare global {
    interface Window {}
}

function DefaultRoute(params: IRouteParams) {
    return <Image fit="fill" maxW={500} padding={5} src="/images/events/dinner-01-20-22.jpg" alt="Pa Dong Event" />;
}

export interface IRouteParams {
    path?: string;
    auth?: string;
    startAuth: () => void;
    route: (path: string) => void;
    allData: AllDataHydrated;
    refresh: () => void;
}

async function verifyAuth(auth: string): Promise<boolean> {
    try {
        const response = await fetch(`${RESTAURANT_API_URL}/verifyAuth`, {
            headers: {
                padong_auth: auth,
            },
        });
        if (response.ok) {
            return true;
        }
    } catch (error) {}

    return false;
}

const ROUTES: Record<string, (props: IRouteParams) => JSX.Element> = {
    order: Order,
    serve: Serve,
    kitchen: Kitchen,
    admin: Admin,
    items: Items,
    places: Places,
};

const REFRESH_MS = 5000;

export const App = () => {
    const isOnline = window.navigator.onLine;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_offline, setIsOffline] = React.useState(!isOnline);

    const [auth, setAuth] = React.useState(undefined as unknown as string | undefined);

    const [data, setData] = React.useState({
        orders: [],
        places: {},
        items: [],
        adminOptions: initialOptions,
    } as AllDataHydrated);

    const [isLoaded, setIsLoaded] = React.useState(false);

    const origPathArray = window.location.pathname.split('/');

    const [[route, routePath], setRouteArray] = React.useState(origPathArray.slice(1, origPathArray.length));

    let FinalRoute = DefaultRoute;

    if (ROUTES[route] !== undefined) {
        FinalRoute = ROUTES[route];
    }

    const handleNavigate = async (path: string) => {
        window.history.pushState(null, '', path);
        const pathArray = path.split('/');
        setRouteArray(pathArray.slice(1, pathArray.length));
    };

    const handleStartAuth = async () => {
        const auth = window.prompt('Please enter auth');
        if (!auth) {
            window.alert('Password required');
            //window.location.reload();
        } else {
            verifyAuth(auth).then((success) => {
                if (success) {
                    window.localStorage.setItem(LOCAL_AUTH_KEY, auth);
                    setAuth(auth);
                    window.history.pushState(null, '', '/admin');
                    window.location.reload();
                } else {
                    window.alert('Password invalid');
                    //window.location.reload();
                }
            });
        }
    };

    const onRefresh = async (authInfo: string) => {
        if (authInfo !== undefined) {
            try {
                const allData = await getAllHydrated(authInfo);
                setData(allData);
            } catch (error) {
                console.log(error);
            }
        }
    };

    const handleClearAuth = () => {
        const confirm = window.confirm('Are you sure you want to logout?');
        if (!confirm) {
            return;
        }
        window.localStorage.removeItem(LOCAL_AUTH_KEY);
        window.location.reload();
        setAuth(undefined);
    };

    React.useEffect(() => {
        const authInfo = window.localStorage.getItem(LOCAL_AUTH_KEY);
        if (authInfo !== null) {
            onRefresh(authInfo);
            setAuth(authInfo);
        } else if (route === 'login') {
            handleStartAuth();
        }

        window.addEventListener('online', () => setIsOffline(false));
        window.addEventListener('offline', () => setIsOffline(true));

        setTimeout(() => {
            setIsLoaded(true);
        }, 200);
        if (authInfo !== null) {
            const interval = setInterval(() => {
                onRefresh(authInfo);
            }, REFRESH_MS);
            return () => clearInterval(interval);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!isLoaded) {
        return (
            <ChakraProvider theme={theme}>
                <Center height="100vh" color="white">
                    <Spinner color="orange" />
                </Center>
            </ChakraProvider>
        );
    }

    return (
        <ChakraProvider theme={theme}>
            {!isOnline && (
                <Alert status="error" width="full" variant="solid" height="100px">
                    <AlertIcon />
                    <AlertTitle mr={2}>You are offline!</AlertTitle>
                    <AlertDescription>Check internet connectivity.</AlertDescription>
                </Alert>
            )}
            <Box textAlign="center" fontSize="xl" mb={5}>
                <Grid maxW="100hh">
                    <HStack justifyContent="flex-end" padding={5} width="full">
                        {auth !== undefined && (
                            <ButtonGroup isAttached={true} size="sm" variant={'outline'}>
                                <Button
                                    isActive={route.startsWith('/order/')}
                                    variant={route.startsWith('order') ? 'solid' : 'outline'}
                                    onClick={() => handleNavigate('/order/1')}
                                    colorScheme="green"
                                >
                                    +
                                </Button>
                                <Button isActive={route === 'serve'} onClick={() => handleNavigate('/serve')}>
                                    Orders
                                    <Tag size="sm" ml={2}>
                                        {data.orders.filter((o) => o.status === OrderStatus.PENDING).length}
                                    </Tag>
                                </Button>
                                <Button isActive={route === 'items'} onClick={() => handleNavigate('/items')}>
                                    Serve
                                </Button>
                                <Button isActive={route === 'kitchen'} onClick={() => handleNavigate('/kitchen')}>
                                    Kitchen
                                </Button>
                                <Button colorScheme="red" onClick={handleClearAuth}>
                                    <SmallCloseIcon />
                                </Button>
                            </ButtonGroup>
                        )}
                        <ColorModeSwitcher justifySelf="flex-end" size="xs" />
                    </HStack>

                    <VStack spacing={8}>
                        <FinalRoute
                            path={routePath}
                            auth={auth}
                            startAuth={handleStartAuth}
                            route={handleNavigate}
                            allData={data}
                            refresh={() => onRefresh(auth || '')}
                        />
                    </VStack>
                </Grid>
            </Box>
        </ChakraProvider>
    );
};
