export type Place = {
    id: string;
    slug: string;
    image?: string;
};

export interface Order {
    id: string;
    name: string;
    placeId: string;
    status: OrderStatus;
    orderNumber: number;
    ping: boolean;
    ts: string;
    modTs: string;
    completeTs?: string;
    payment?: 'cash' | 'card' | 'comp';
}

export type Item = {
    id: string; //unique; used as index / key for Item settings
    type: ItemTypes;
    orderId: string; //pointer to order
    status: ItemStatus;
    ts: string;
    modTs: string;
    completeTs?: string;
};

//normalized for frontend component use
export interface HydratedOrder extends Order {
    items: HydratedItem[];
    lastItemServeTime: string;
}

//normalized for frontend component use
export type HydratedItem = {
    id: string; //unique; used as index / key for Item settings
    orderId: string; //pointer to order
    order: Order;
    status: ItemStatus;
    ts: string;
    modTs: string;
    completeTs?: string;
    type: ItemTypes;
    typeDetails: ItemType;
};

export enum ItemStatus {
    PENDING = 'pending',
    CONFIRMED = 'confirmed',
    SERVED = 'served',
    CANCELLED = 'cancelled',
}

export enum ItemTypes {
    'korean-tacos-chicken' = 'korean-tacos-chicken',
    'korean-tacos-veggie' = 'korean-tacos-veggie',
    'spring-rolls-fried' = 'spring-rolls-fried',
    'korean-cauliflower-fried' = 'korean-cauliflower-fried',
    'choco-truffles' = 'choco-truffles',
}

export type ItemType = {
    name: string; //unique; used as index / key for Item settings
    displayName: string;
    description: string;
    gf: boolean;
    vegan: 'vegetarian' | 'vegan' | boolean;
    vegetarian?: boolean;
    priceGTQ: number;
    costGTQ: number;
    image: string;
    serveOrder: number; //int for ordering the items to serve
};

//NOTE - ensure that name & key match (this should be guarded by better typing)
export const ItemsDict: Record<ItemTypes, ItemType> = {
    [ItemTypes['korean-tacos-chicken']]: {
        name: ItemTypes['korean-tacos-chicken'],
        displayName: 'Korean Chicken Tacos',
        description: 'Sweet marinated pulled chicken topped with a cabbage slaw, homemade pickled red onions and a white miso sauce on flour tortillas.',
        priceGTQ: 60,
        costGTQ: 30,
        gf: false,
        vegan: false,
        image: '/icons/taco.png',
        serveOrder: 2,
    },
    [ItemTypes['korean-tacos-veggie']]: {
        name: ItemTypes['korean-tacos-veggie'],
        displayName: 'Korean Veggie Tacos',
        description: 'Sweet cauliflower topped with a cabbage slaw, homemade pickled red onions and a white miso sauce on flour tortillas.',
        priceGTQ: 60,
        costGTQ: 30,
        gf: false,
        vegan: false,
        vegetarian: true,
        image: '/icons/taco2.png',
        serveOrder: 2,
    },
    [ItemTypes['spring-rolls-fried']]: {
        name: 'spring-rolls-fried',
        displayName: 'Fried Spring Rolls',
        description: 'Fried spring rolls served with peanut sauce.',
        priceGTQ: 40,
        costGTQ: 20,
        gf: true,
        vegan: true,
        image: '/icons/thai/spring-rolls.png',
        serveOrder: 1,
    },
    [ItemTypes['korean-cauliflower-fried']]: {
        name: ItemTypes['korean-cauliflower-fried'],
        displayName: 'Korean Fried Cauliflower',
        description: 'Breaded and fried in a sweet and spicy sauce topped with sesame seeds and green onions.',
        priceGTQ: 40,
        costGTQ: 20,
        gf: false,
        vegan: true,
        image: '/icons/cauliflower.png',
        serveOrder: 1,
    },
    [ItemTypes['choco-truffles']]: {
        name: ItemTypes['choco-truffles'],
        displayName: 'Vegan Chocolate Truffles',
        description: 'Yummy chocolate balls covered in nuts',
        priceGTQ: 30,
        costGTQ: 15,
        gf: true,
        vegan: true,
        image: '/icons/choc-cake.png',
        serveOrder: 3,
    },
};

export type OrderDTO = {
    items: ItemTypes[];
    name: string;
    placeId: string;
};

export enum OrderStatus {
    PENDING = 'pending',
    CONFIRMED = 'confirmed',
    CANCELLED = 'cancelled',
    COMPLETE = 'complete',
}

export const LOCAL_STORAGE_ORDERS = 'padong_dinner_orderNumbers';

export enum MODE {
    ORDER,
    VIEW,
}
export const StatusOrder: Array<OrderStatus> = [OrderStatus.PENDING, OrderStatus.CONFIRMED, OrderStatus.COMPLETE, OrderStatus.CANCELLED];

export const statusColorDict: Record<OrderStatus, string> = {
    pending: 'red',
    cancelled: 'teal',
    complete: 'green',
    confirmed: 'orange',
};

export type PlaceDTO = {
    slug: string;
    image?: string;
};

type DisabledItem = Record<ItemTypes, boolean>;

export type AdminOptions = {
    disabledItems: DisabledItem;
};

export const initialOptions: AdminOptions = {
    disabledItems: {
        [ItemTypes['korean-cauliflower-fried']]: false,
        [ItemTypes['choco-truffles']]: false,
        [ItemTypes['korean-tacos-chicken']]: false,
        [ItemTypes['korean-tacos-veggie']]: false,
        [ItemTypes['spring-rolls-fried']]: false,
    },
};

export type AllData = { orders: HydratedOrder[]; items: HydratedItem[]; places: Record<string, Place>; adminOptions: AdminOptions };

export type AllDataHydrated = { orders: HydratedOrder[]; items: HydratedItem[]; places: Record<string, Place>; adminOptions: AdminOptions };
