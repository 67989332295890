import { DateTime } from 'luxon';

export const timeAgo = (dateString: string) => {
    const now = DateTime.local();
    const past = DateTime.fromISO(dateString);
    const diff = now.diff(past, 'seconds');
    if (diff.seconds < 0) {
        return `just now`;
    }
    if (diff.seconds < 60) {
        return `${Math.floor(diff.seconds)} seconds`;
    }

    if (diff.seconds > 60) {
        return `${Math.floor(diff.seconds / 60)} minutes`;
    }
};

export const timeAgoSeconds = (dateString: string) => {
    const now = DateTime.local();
    const past = DateTime.fromISO(dateString);
    const diff = now.diff(past, 'seconds');
    return Math.floor(diff.seconds);
};

export const timeAgoFormat = (timeSeconds: number) => {
    if (timeSeconds < 0) {
        return `just now`;
    }
    if (timeSeconds < 60) {
        return `${Math.floor(timeSeconds)} seconds`;
    }

    if (timeSeconds > 60 && timeSeconds < 7200) {
        return `${Math.round(timeSeconds / 60)} minutes`;
    }

    if (timeSeconds > 60 && timeSeconds > 7200) {
        return `${Math.round(timeSeconds / 60 / 60)} hours`;
    }
};
