import React from 'react';
import { Divider, Text, Box, VStack, Button, HStack, Input, Select } from '@chakra-ui/react';

import { IRouteParams } from '../../App';
import { Order, OrderStatus } from '../../common/types';
import { confirmOrder, updateOrderStatus } from '../../common/api';
import OrderStatusItem from '../shared/OrderItem';
import { getOrderStatus } from '../../common/selectors';

export default function Serve(props: IRouteParams) {
    const [statusFilter, setStatusFilter] = React.useState(OrderStatus.PENDING as string);
    const [nameFilter, setNameFilter] = React.useState('' as string);
    const [orderFilter, setOrderFilter] = React.useState('all' as string);

    const { orders } = props.allData;

    const onUpdateOrderStatus = async (orderId: string, orderStatus: OrderStatus) => {
        await updateOrderStatus(orderId, orderStatus, props.auth || '');
        props.refresh();
    };

    const handleConfirmOrder = async (orderId: string, payment: Order['payment']) => {
        await confirmOrder(orderId, payment, props.auth || '');
        props.refresh();
    };

    let filteredOrders = orders.filter((order) => {
        if (statusFilter === 'all') {
            return true;
        }
        if (statusFilter === OrderStatus.PENDING) {
            if (order.ping) {
                return true;
            }
        }
        return getOrderStatus(order) === statusFilter;
    });
    filteredOrders = filteredOrders.filter((order) => {
        if (orderFilter === 'all') {
            return true;
        }
        return order.orderNumber.toString() === orderFilter;
    });

    return (
        <Box borderWidth="1px" borderRadius="lg" width="90%" overflowWrap="break-word" padding="5" maxWidth={600}>
            <Button onClick={props.refresh}>Refresh</Button>

            <VStack alignItems="flex-start" justifyContent={'flex-start'}>
                <Text>Orders</Text>
                <HStack justifyItems={'space-around'} width="full">
                    <Select size="sm" onChange={(e) => setStatusFilter(e.target.value)} value={statusFilter}>
                        <option value={OrderStatus.PENDING}>Awaiting Payment & Pings</option>
                        <option value={OrderStatus.CONFIRMED}>Awaiting Food</option>
                        <option value={'all'}>All</option>
                    </Select>
                </HStack>
                <HStack justifyItems={'space-around'} width="full">
                    <Select size="sm" onChange={(e) => setOrderFilter(e.target.value)} value={orderFilter}>
                        <option value={'all'}>All Orders</option>
                        {orders.map((o, index) => (
                            <option key={index} value={o.orderNumber}>
                                {o.orderNumber} - {o.name}
                            </option>
                        ))}
                    </Select>
                </HStack>
                <HStack justifyItems={'space-around'} width="full">
                    <Input size="sm" onChange={(e) => setNameFilter(e.target.value)} value={nameFilter} placeholder="filter by name" />
                </HStack>
                <Divider />
                <Text fontSize={14}>{filteredOrders.length} order(s) </Text>
                <Divider />
                {filteredOrders.map((order, index) => (
                    <OrderStatusItem
                        key={index}
                        order={order}
                        updateOrderStatus={onUpdateOrderStatus}
                        refreshParent={props.refresh}
                        onConfirmOrder={handleConfirmOrder}
                    />
                ))}
            </VStack>
        </Box>
    );
}
