import React from 'react';
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Heading,
    HStack,
    Badge,
    Text,
    VStack,
    Divider,
    UnorderedList,
    ListItem,
    useToast,
    Spinner,
    Switch,
    Input,
    Image,
    Select,
} from '@chakra-ui/react';

import { IRouteParams } from '../../App';
import {
    AdminOptions,
    initialOptions,
    HydratedOrder,
    MODE,
    statusColorDict,
    OrderDTO,
    ItemTypes,
    ItemStatus,
    OrderStatus,
    ItemsDict,
} from '../../common/types';
import { getOrderStatus, hydratedOrderPrice, hydratedOrderPriceTotal } from '../../common/selectors';
import { timeAgo } from '../../common/orderUtils';
import { addOrder, getAdminOptions, getOrdersByPlaceId, toggleOrderPing } from '../../common/api';
import { CheckIcon, WarningIcon, CloseIcon } from '@chakra-ui/icons';

function OrderStatusItem(props: { order: HydratedOrder; pingOrder: (orderNumber: string | number) => void }) {
    const orderTimeAgo = timeAgo(props.order.ts);
    const orderStatus = getOrderStatus(props.order);
    return (
        <>
            <VStack padding={2} width="full" spacing={2}>
                <HStack width="full" justifyContent="flex-start" spacing={15}>
                    <Text fontSize={16} fontWeight={600}>
                        Order #{props.order.orderNumber} - {props.order.name}
                    </Text>
                    <Badge float="right" colorScheme={statusColorDict[orderStatus]}>
                        {orderStatus}
                    </Badge>
                </HStack>

                <HStack width="full" justifyContent="flex-start">
                    <UnorderedList fontSize={13} textAlign="left">
                        {props.order.items.map((item, index) => (
                            <ListItem key={index}>
                                {item.status !== ItemStatus.CANCELLED ? (
                                    `${item.typeDetails.displayName} ${item.status === ItemStatus.SERVED ? '(Served)' : ''} - Q ${item.typeDetails.priceGTQ}`
                                ) : (
                                    <s>{item.typeDetails.displayName}</s>
                                )}
                            </ListItem>
                        ))}
                    </UnorderedList>
                </HStack>
                <HStack width="full" justifyContent="flex-start" spacing={15}>
                    {props.order.items.some((i) => i.status === ItemStatus.CANCELLED) ? (
                        <Text fontSize={17}>
                            Q {hydratedOrderPrice(props.order)} (Q {hydratedOrderPriceTotal(props.order)} original)
                        </Text>
                    ) : (
                        <Text fontSize={17}>Q {hydratedOrderPrice(props.order)}</Text>
                    )}
                </HStack>
                <HStack width="full" justifyContent="flex-start" spacing={15}>
                    <Text fontSize={15}>
                        <i>Placed {orderTimeAgo} ago</i>
                    </Text>
                </HStack>
                {!props.order.ping ? (
                    <Button
                        leftIcon={<WarningIcon />}
                        width="full"
                        colorScheme="orange"
                        size="sm"
                        onClick={() => props.pingOrder(props.order.orderNumber)}
                        disabled={getOrderStatus(props.order) === OrderStatus.CANCELLED || getOrderStatus(props.order) === OrderStatus.COMPLETE}
                    >
                        Ping kitchen for order #{props.order.orderNumber}
                    </Button>
                ) : (
                    <Button leftIcon={<CloseIcon />} size="sm" width="full" colorScheme="red" onClick={() => props.pingOrder(props.order.orderNumber)}>
                        Cancel ping kitchen
                    </Button>
                )}
            </VStack>
            <Divider />
        </>
    );
}

const INITIAL_ORDER_DTO: OrderDTO = {
    name: '',
    items: [],
    placeId: '',
};

const REFRESH_MS = 15000;

export default function NewOrder(props: IRouteParams) {
    let placeId = props.path;

    const [selectedPlaceId, setPlaceId] = React.useState(placeId);

    if (props.auth !== undefined) {
        placeId = selectedPlaceId;
    }

    const toast = useToast();

    const [adminOptions, setAdminOptions] = React.useState(initialOptions);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [showCompleted, setShowCompleted] = React.useState(false);
    const [invalidPlace, setInvalidPlace] = React.useState(false);

    const [thisOrder, setThisOrder] = React.useState(INITIAL_ORDER_DTO);

    const [activeOrders, setActiveOrders] = React.useState([] as Array<HydratedOrder>);

    const [mode, setMode] = React.useState(MODE.ORDER);

    const [isProcessing, setIsProcessing] = React.useState(false);

    const handleUpdateOrderName = (newName: string) => {
        setThisOrder({
            ...thisOrder,
            name: newName,
        });
    };

    const handleAddItem = (e: any) => {
        const itemName = e.target.name as ItemTypes;
        setThisOrder({
            ...thisOrder,
            items: thisOrder.items.concat([itemName]),
        });
    };

    const handleRemoveItem = (e: any) => {
        const itemName = e.target.name as ItemTypes;

        const itemIndex = thisOrder.items.findIndex((i) => i === itemName);
        if (itemIndex !== -1) {
            let newItems = thisOrder.items;
            newItems.splice(itemIndex, 1);
            setThisOrder({
                ...thisOrder,
                items: newItems,
            });
        }
    };

    const onSubmitOrder = async () => {
        setIsProcessing(true);
        const currentAdminOptions = await updateAdminOptions();
        setAdminOptions(currentAdminOptions);

        const { items } = thisOrder;

        for (const item of items) {
            if (currentAdminOptions.disabledItems[item]) {
                toast({
                    title: `${ItemsDict[item].displayName} is sold out =(`,
                    description: 'Please select another item',
                    status: 'error',
                    duration: 4000,
                    isClosable: true,
                });
                setIsProcessing(false);
                return;
            }
        }

        const finalOrder: OrderDTO = thisOrder;

        const orderResponse = await addOrder(finalOrder as OrderDTO, placeId || '');

        if (orderResponse) {
            await onRefresh();
            onResetOrder();

            toast({
                title: `Order #${orderResponse.orderNumber} placed`,
                description: (
                    <VStack>
                        <Text>We will come by your table to collect payment.</Text>
                        <Text fontStyle={'italic'}>Click "Ping Kitchen" on your order if you have questions.</Text>
                    </VStack>
                ),
                status: 'success',
                containerStyle: {
                    fontSize: 18,
                },
                duration: 10000,
                isClosable: true,
            });
            window.scrollTo(0, 0);
            setMode(MODE.VIEW);
        }

        setIsProcessing(false);
    };

    function onResetOrder() {
        setThisOrder(INITIAL_ORDER_DTO);
    }

    async function updateAdminOptions(): Promise<AdminOptions> {
        const adminOptions = await getAdminOptions();
        setAdminOptions(adminOptions);
        return adminOptions;
    }

    const handlePingOrder = async (orderNumber: string | number) => {
        const orders = await onRefresh();
        const thisOrderNext = orders?.find((o) => o.orderNumber === orderNumber);
        const thisOrderCurrent = activeOrders.find((o) => o.orderNumber === orderNumber);

        if (thisOrderNext?.ping === thisOrderCurrent?.ping) {
            await toggleOrderPing(orderNumber);
            onRefresh();
        }
        //changed; skip action
    };

    const onRefresh = async (): Promise<HydratedOrder[] | undefined> => {
        if (placeId === undefined) {
            return;
        }
        let theseOrders;
        try {
            theseOrders = await getOrdersByPlaceId(placeId);
            setActiveOrders(theseOrders);
        } catch (error) {
            setInvalidPlace(true);
        }
        try {
            await updateAdminOptions();
        } catch (error) {
            console.log(error);
        }
        setIsLoaded(true);

        return theseOrders;
    };

    React.useEffect(() => {
        //check if orders exist at this table
        onRefresh();

        //start refresh interval
        const interval = setInterval(() => {
            onRefresh();
        }, REFRESH_MS);
        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [placeId]);

    if (!isLoaded) {
        return (
            <Box borderWidth="1px" borderRadius="lg" overflowWrap="break-word" padding="10">
                <Spinner color="orange" />
            </Box>
        );
    }

    if (!placeId || invalidPlace) {
        return (
            <Box borderWidth="1px" borderRadius="lg" overflowWrap="break-word" padding="10">
                <VStack>
                    <Heading as="h2" size="1xl" mb={5}>
                        Error Occurred
                    </Heading>
                    <Text>Please retry QR code</Text>
                </VStack>
            </Box>
        );
    }

    if (mode === MODE.VIEW) {
        //view active orders instead
        return (
            <Box borderWidth="1px" borderRadius="lg" width="90%" maxWidth={450} overflowWrap="break-word" padding="5">
                <VStack spacing={5}>
                    <HStack width="full" justifyContent="center">
                        <Button colorScheme="teal" type="submit" size="sm" onClick={() => setMode(MODE.ORDER)}>
                            Place a new order
                        </Button>
                    </HStack>
                    <Divider />
                </VStack>
                <VStack mt={3}>
                    <Heading as="h3" size="1xl" mb={2}>
                        {showCompleted ? 'All' : 'Current'} orders for <b>table #{placeId}</b>
                    </Heading>
                    <FormControl display="flex" alignItems="center" justifyContent="center">
                        <FormLabel htmlFor="view-completed" mb="0">
                            show all
                        </FormLabel>
                        <Switch id="view-completed" onChange={(e) => setShowCompleted(e.target.checked)} isChecked={showCompleted} />
                    </FormControl>
                    <Divider />
                    {activeOrders
                        .filter((o) => (showCompleted ? true : getOrderStatus(o) !== OrderStatus.COMPLETE && getOrderStatus(o) !== OrderStatus.CANCELLED))
                        .reverse()
                        .map((order, index) => (
                            <OrderStatusItem order={order} key={order.id} pingOrder={handlePingOrder} />
                        ))}
                </VStack>
            </Box>
        );
    }

    const orderTotal = thisOrder.items.reduce((acc, i) => acc + ItemsDict[i].priceGTQ, 0);

    const queuedItems = thisOrder.items.reduce((acc, item) => {
        if (acc[item] !== undefined) {
            return {
                ...acc,
                [item]: acc[item] + 1,
            };
        }

        return {
            ...acc,
            [item]: 1,
        };
    }, {} as Record<ItemTypes, number>);

    return (
        <Box borderWidth="1px" borderRadius="lg" width="90%" maxWidth={450} overflowWrap="break-word" padding="5">
            {props.auth !== undefined && (
                <VStack spacing={5} mb={5}>
                    <Text>Select Table:</Text>
                    <Select onChange={(e) => setPlaceId(e.target.value)} value={placeId}>
                        {Object.keys(props.allData.places).map((placeId, index) => {
                            return (
                                <option key={index} value={placeId}>
                                    Table #{placeId} - {props.allData.places[placeId].slug}
                                </option>
                            );
                        })}
                    </Select>
                    <Divider />
                </VStack>
            )}
            {activeOrders.length > 0 && (
                <VStack spacing={5}>
                    <HStack width="full" justifyContent="center">
                        <Button colorScheme="teal" type="submit" size="sm" onClick={() => setMode(MODE.VIEW)}>
                            This table has{' '}
                            {activeOrders.filter((o) => getOrderStatus(o) !== OrderStatus.CANCELLED && getOrderStatus(o) !== OrderStatus.COMPLETE).length} open
                            orders
                        </Button>
                    </HStack>
                    <Divider />
                </VStack>
            )}
            <VStack mt={3}>
                <Heading as="h3" size="1xl" mb={5}>
                    Place an order for <b>table #{placeId}</b>
                </Heading>

                <FormControl id="name">
                    <FormLabel>
                        Name
                        <i style={{ color: 'red' }}>*</i>
                    </FormLabel>
                    <Input
                        type="name"
                        placeholder="enter name for order"
                        value={thisOrder.name}
                        onChange={(e) => handleUpdateOrderName(e.target.value)}
                        mb={5}
                    />
                </FormControl>
                <Divider />
                {(Object.keys(ItemsDict) as ItemTypes[]).map((item, index) => (
                    <VStack key={index} width="full" spacing={3}>
                        <HStack width="full" justifyContent="center">
                            {!adminOptions.disabledItems[item] ? (
                                <Text>{ItemsDict[item].displayName}</Text>
                            ) : (
                                <Text>
                                    <s>{ItemsDict[item].displayName}</s> (sold out)
                                </Text>
                            )}
                        </HStack>

                        <HStack width="full" justifyContent="space-between" spacing={5} mb={5}>
                            <VStack>
                                <Image borderRadius="full" maxWidth="50px" src={ItemsDict[item].image} alt={ItemsDict[item].name} />
                            </VStack>
                            <VStack alignItems="flex-start" width="full">
                                <Text fontSize={14} fontStyle={'italic'} textAlign="left">
                                    {ItemsDict[item].description}
                                </Text>
                            </VStack>
                            <VStack alignItems="flex-start">
                                {!adminOptions.disabledItems[item] ? (
                                    <Text>Q{ItemsDict[item].priceGTQ}</Text>
                                ) : (
                                    <Text>
                                        <s>Q{ItemsDict[item].priceGTQ}</s>
                                    </Text>
                                )}
                            </VStack>
                        </HStack>
                        <HStack width="full" justifyContent="flex-start">
                            {ItemsDict[item].gf && (
                                <Badge colorScheme="orange" size="lg">
                                    Gluten Free
                                </Badge>
                            )}
                            {ItemsDict[item].vegan && (
                                <Badge colorScheme="green" size="lg">
                                    Vegan
                                </Badge>
                            )}
                            {ItemsDict[item].vegetarian === true && (
                                <Badge colorScheme="green" size="lg">
                                    Vegetarian
                                </Badge>
                            )}
                        </HStack>
                        <HStack width="full" padding={2}>
                            <Button
                                colorScheme="red"
                                width="full"
                                name={item}
                                onClick={handleRemoveItem}
                                disabled={thisOrder.items.filter((i) => ItemsDict[i].name === item).length < 1}
                            >
                                -
                            </Button>
                            <Button width="full">{thisOrder.items.filter((i) => ItemsDict[i].name === item).length}</Button>
                            <Button colorScheme="green" width="full" name={item} onClick={handleAddItem} disabled={adminOptions.disabledItems[item]}>
                                +
                            </Button>
                        </HStack>
                        <Divider />
                    </VStack>
                ))}
                <UnorderedList fontSize={13} textAlign="left">
                    {(Object.keys(queuedItems) as ItemTypes[]).map((item, index) => (
                        <ListItem key={index}>
                            {queuedItems[item]} {ItemsDict[item].displayName} - Q {ItemsDict[item].priceGTQ * queuedItems[item]}
                        </ListItem>
                    ))}
                </UnorderedList>

                <HStack width="full" justifyContent="center" padding={4}>
                    <Button
                        leftIcon={<CheckIcon />}
                        colorScheme="green"
                        type="submit"
                        disabled={thisOrder.name === '' || isProcessing || thisOrder.items.length < 1}
                        onClick={() => onSubmitOrder()}
                    >
                        Place Order Q {orderTotal} (~${Math.round(orderTotal / 7.7)})
                    </Button>
                </HStack>
                {thisOrder.name === '' && (
                    <Text fontSize={12} textColor={'red.300'}>
                        (must enter your name before you can order)
                    </Text>
                )}
            </VStack>
        </Box>
    );
}
